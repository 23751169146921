import { Tooltip } from 'react-tooltip';
import Link from 'next/link';

import { QuestionIcon } from '../../modules/Icons';

const BlackFridayTooltip: React.FC<any> = () => {
  return (
    <>
      <button
        data-tooltip-id="black-friday-tooltip"
        className="ml-1 2xl:ml-2 1900p:ml-3 max-2xl:align-sub 2xl:pt-3 2xl:scale-[1.2] inline-block"
      >
        <QuestionIcon color="#fff" />
      </button>
      <Tooltip
        clickable={true}
        id="black-friday-tooltip"
        place="bottom-start"
        noArrow={true}
        offset={4}
        opacity={1}
        style={{
          boxShadow: '0px 4px 30px 0px rgba(0, 0, 0, 0.25)',
          background: '#15191B',
          zIndex: 50,
          padding: '10px',
          fontFamily: 'Inter',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '22px',
          width: '405px',
          borderRadius: '5px',
          textTransform: 'none'
        }}
      >
        <p>
          The earlier you buy, the more you save in this year’s Black Friday sale! Each discount can
          only be claimed a limited number of time. After that, the offer will expire forever.
        </p>
        <p className="mt-3">
          For an even bigger discount,
          <Link className="text-a-blue" href="/pricing">
            {' '}
            join AVFX+
          </Link>
          . By subscribing to AVFX+, you’ll receive the Black Friday discount plus up to{' '}
          <span className="font-bold">an additional 40% Off</span> on all orders!
        </p>
      </Tooltip>
    </>
  );
};

export default BlackFridayTooltip;
