import React from 'react';
import { Dialog } from '@headlessui/react';
import { ExitIcon } from '../../modules/Icons';
import Link from 'next/link';

export default function TeamOrStudioAssistDialog(props) {
  const { dialogIsOpen, setDialogIsOpen } = props;
  const paragraphStyle = {
    width: '700px',
  };
  const closeDialogIconStyle = {
    position: 'absolute',
    top: '35px',
    right: '26px',
    zIndex: '1000',
    cursor: 'pointer',
    width: '24px',
    height: '24px',
  };
  const notQualifiedEmphasizeStyle = {
    textDecoration: 'underline',
    fontStyle: 'italic',
  };
  const listStylePosition: ('inherit' | 'initial' | 'inside' | 'outside' | 'revert' | 'unset') = 'inside';
  const listStyle = {
    listStyleType: 'disc',
    listStylePosition: listStylePosition,
  };

  return(
    <>
    <Dialog
      open={dialogIsOpen === true}
      onClose={() => {
        setDialogIsOpen(false);
      }}
      className="relative z-[51]"
    >
      <div
        className="fixed inset-0 overflow-y-auto flex items-center justify-center bg-black/60"
      >
        <Dialog.Panel>
          <div className="unlock-modal bg-a-dark-gray shadow-lg drop-shadow-lg rounded-[5px] pt-10 px-12 pb-12 text-white">
            <ExitIcon style={closeDialogIconStyle} onClick={() => setDialogIsOpen(false)}/>
            <div className="product-page text-white">
              <h1 className="eurostile uppercase text-[28px] font-bold tracking-wide leading-9">who is a team or studio?</h1>
              <p className="inter text-[16px] text-gray-500 mt-4" style={paragraphStyle}>
              A team is any company or entity comprised of more than 1 person, such as studios, agencies, and more. A <strong>“Startup”</strong> or <strong>“Enterprise”</strong> license is required for all teams regardless of size or budget.
              <br/><br/>
              The <strong>“Individual”</strong> license is intended only for a single person using ActionVFX products, such as a freelancer, hobbyist, or student. This is a fully commercial license, but it is granted only to an individual, never a company. Meaning, the license is bound to the one specific person purchasing the assets, not to a corporate entity.              </p>
              <h2 className="inter text-[21px] font-bold text-a-blue mt-10">You <span style={notQualifiedEmphasizeStyle}>do not</span> qualify for an “Individual” license if:</h2>
              <p className="inter text-[16px] text-gray-500 mt-2.5" style={paragraphStyle}>
                <ul style={listStyle}>
                  <li>You’re a studio, agency, or other types of team or entity comprised of more than 1 person</li>
                  <li>You’ll store the purchased assets on a shared storage space where they can be accessed by other people</li>
                  <li>You’ll allow different people to use the assets throughout the lifetime of your license</li>
                  <li>You’re an independent contractor working inside a studio (the studio would need their own license)</li>
                  <li>You need your license to be assigned to your company and not 1 individual person</li>
                </ul>
              </p>
              <p className="inter text-[16px] text-gray-500 mt-2.5" style={paragraphStyle}>
                Purchasing an “Individual” license despite meeting 1 or more of the conditions above is a material breach of our
                <Link className="text-a-blue" href="/license">
                  {' '}
                  License Agreement
                </Link> and will invalidate your license which will affect the legal standing of any projects the purchased assets were used in.
              </p>
              <p className="inter text-[16px] text-gray-500 mt-2.5" style={paragraphStyle}>
              Choosing the correct license helps us stay in business to continue providing you with the highest quality products. We appreciate you treating us fairly! Please
              <Link className="text-a-blue" href="#" id="launchIntercomChat" onClick={e => e.preventDefault()}>
                {' '}
                talk to us
              </Link> if you have any questions.
              </p>
            </div>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
    </>
  )
}
