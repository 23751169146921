import { User } from '../types/api/UsersTypes';

import { AxiosError } from 'axios';
import { StripeConfirmIntentResponse } from '../types/api/stripe/confirmIntentResponse';
import { StripeErrorResponse } from '../types/api/stripe/error';
import { CheckoutResponse } from '../types/helpers/checkoutHelpers';
import { StripeCreatePaymentIntentResponse } from '../types/api/stripe/createPaymentIntentResponse';
import { StripePaymentIntentConfirmData } from '../types/api/stripe/paymentIntentConfirm';
import { StripePaymentMethodsList } from '../types/api/stripe/paymentMethodsList';
import { StripePaymentMethodData } from '../types/api/stripe/paymentMethod';
import { ApiError } from '../types/api/ErrorsTypes';

import stripeHelpers from './stripe/helpers';

const checkoutUsingNewCard = async (
  user: User,
  elements: any,
  stripeInstance: any,
  addOnConfig?: {
    amount: number,
  },
): Promise<CheckoutResponse | StripeErrorResponse | AxiosError> => {
  const {
    createSetupIntent,
    confirmSetupIntent,
    createPaymentIntent,
    confirmPaymentIntent,
  } = stripeHelpers;

  const createSetupIntentResult = await createSetupIntent(user);
  if ((createSetupIntentResult as StripeErrorResponse).error) return createSetupIntentResult as StripeErrorResponse;
  const clientSecret = (createSetupIntentResult as { setupIntentClientSecret: string }).setupIntentClientSecret;
  const confirmSetupIntentResult = await confirmSetupIntent(clientSecret, elements, stripeInstance);
  if ((confirmSetupIntentResult as StripeErrorResponse)?.error)
    return confirmSetupIntentResult as StripeErrorResponse;

  const confirmSetupIntentData = confirmSetupIntentResult as StripeConfirmIntentResponse;
  if (!addOnConfig) return { confirmSetupIntentData };

  const payment_method_id = confirmSetupIntentData.setupIntent.payment_method;
  const { amount } = addOnConfig;
  const createPaymentIntentResult = await createPaymentIntent(amount, user, payment_method_id);
  if ((createPaymentIntentResult as StripeErrorResponse).error) return createPaymentIntentResult as StripeErrorResponse;

  const createPaymentIntentData = createPaymentIntentResult as StripeCreatePaymentIntentResponse;
  const confirmPaymentIntentResult = await confirmPaymentIntent(createPaymentIntentData.paymentIntent);
  if ((confirmPaymentIntentResult as StripeErrorResponse).error) return confirmPaymentIntentResult as StripeErrorResponse;

  const confirmPaymentIntentData = confirmPaymentIntentResult as StripePaymentIntentConfirmData;
  return {
    confirmSetupIntentData,
    confirmPaymentIntentData,
  };
};

const checkoutUsingSavedCard = async (
  user: User,
  stripeInstance: any,
  cardId?: string,
): Promise<CheckoutResponse | StripeErrorResponse | AxiosError | ApiError> => {
  const {
    createSetupIntent,
    confirmSetupIntent,
    getCardsFor,
  } = stripeHelpers;

  let firstCard: StripePaymentMethodData;

  if (!cardId) {
    const cardsResult = await getCardsFor(user);
    if ((cardsResult as ApiError).error) return cardsResult as ApiError;
    firstCard = (cardsResult as StripePaymentMethodsList).data[0];
  }

  const createSetupIntentResult = await createSetupIntent(user, cardId || firstCard.id);
  if ((createSetupIntentResult as StripeErrorResponse).error) return createSetupIntentResult as StripeErrorResponse;

  const clientSecret = (createSetupIntentResult as { setupIntentClientSecret: string }).setupIntentClientSecret;
  const confirmSetupIntentResult = await confirmSetupIntent(clientSecret, undefined, stripeInstance);
  if ((confirmSetupIntentResult as StripeErrorResponse)?.error)
    return confirmSetupIntentResult as StripeErrorResponse;

  const confirmSetupIntentData = confirmSetupIntentResult as StripeConfirmIntentResponse;
   return { confirmSetupIntentData };
}

const checkoutHelpers = {
  checkoutUsingNewCard,
  checkoutUsingSavedCard,
};

export default checkoutHelpers;
