import OfferPanel from './OfferPanel';
import CountdownTimer from './CountdownTimer';
import BlackFridayTooltip from './BlackFridayTooltip';

import isMobile from '../../hooks/isMobile';
import { useState } from 'react';

const DefaultBanner: React.FC<any> = () => {
  const mobile = isMobile();
  const [tiers, setTiers] = useState([]);

  let visibleTiers = tiers;

  if (mobile) visibleTiers = tiers.filter((tier) => tier.status === 'current');

  return (
    <div className="w-full z-50 md:py-[31px] md:justify-between md:px-4 lg:px-5 4k:justify-center 1900p:px-24 bg-black-friday-banner-red-default flex items-center flex-col md:flex-row md:gap-4 lg:gap-8 xl:gap-12 2xl:gap-16 1900p:gap-20 cursor-default">
      <div className="max-4k:flex-grow block md:hidden lg:block lg:mb-1">
        <h1 className="eurostile leading-none text-white max-md:py-[26px] max-md:text-[40px] lg:text-[40px] xl:text-[48px] 2xl:text-[52px] 1900p:text-[64px] uppercase font-bold block">
          black friday sale
        </h1>
        <div className="hidden lg:block">
          <h3 className="eurostile text-white lg:text-[20px] 2xl:text-[24px] 1900p:text-[28px] 1900p:leading-[36px] uppercase font-bold my-2 2xl:my-4 inline-block">
            3 Offers. Limited Spots. Shop now!
          </h3>
          <BlackFridayTooltip />
        </div>
        {/* {!mobile && <CountdownTimer endDate={saleEndDate} />} */}
      </div>
      {visibleTiers.map((tier: any) => (
        <OfferPanel key={tier.id} tier={tier} />
      ))}
    </div>
  );
};

export default DefaultBanner;
