import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { ENV } from '../../constants/environments';

import DefaultBanner from './DefaultBanner';
import AVFXPlusBanner from './AVFXPlusBanner';

const BlackFridayBannerWrapper: React.FC<any> = () => {
  const router = useRouter();

  function shouldDisplayAVFXPlusBanner(): boolean {
    return router.asPath.indexOf('/pricing') > -1;
  }

  const [displayDefaultBanner, setDisplayDefaultBanner] = useState<boolean>(
    !shouldDisplayAVFXPlusBanner()
  );

  useEffect(() => {
    const _displayDefaultBanner = !shouldDisplayAVFXPlusBanner();
    if (_displayDefaultBanner === displayDefaultBanner) return;
    setDisplayDefaultBanner(_displayDefaultBanner);
  }, [router.asPath]);

  // In case of emergency: to make banner visible - remove below line
  if (ENV.displaySaleBanner !== 'true') return;

  return <div>{displayDefaultBanner ? <DefaultBanner /> : <AVFXPlusBanner />}</div>;
};

export default BlackFridayBannerWrapper;
