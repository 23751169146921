const RemainingSpotsPercentageBar: React.FC<any> = (props) => {
  const { remainingPercentage = 0 } = props;

  return (
    <span className="block w-full h-2.5 rounded-[10px] bg-red-100 relative overflow-hidden">
      <span
        className="absolute left-0 top-0 h-full bg-green-80 rounded-[10px]"
        style={{ width: `${remainingPercentage}%` }}
      />
    </span>
  );
};

export default RemainingSpotsPercentageBar;
