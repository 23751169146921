import { PlanVariantSelectionProps, PlanVariantSelectionStateProps } from '../../types/components/subscription-upgrade-modal/PlanVariantSelection';
import { SubscriptionDetail, PlanVariantResponse } from '../../types/api/SubscriptionTypes';
import { ApplicationState } from '../../types/state/storeTypes';

import { connect } from 'react-redux';

export const activePlanVariantButtonClass = 'bg-a-blue text-white border-a-blue';
export const inactivePlanVariantButtonClass = 'bg-transparent text-gray-600 border-gray-600';
export const disabledPlanVariantButtonClass = 'bg-gray-800 text-gray-600 border-gray-800 cursor-default';

const PlanVariantSelection: React.FC<PlanVariantSelectionProps> = ({
  filteredPlanVariants,
  selectedInterval,
  currentPlan,
  selectedPlanVariant,
  onSelectedPlanVariant,
}) => {
  const getTitle = (): string => selectedPlanVariant?.plan.name || '';

  const getSubtitle = (): string => {
    switch (getTitle()) {
      case 'Individual': return 'For individual artists earning under $200K/year in revenue';
      case 'Startup': return 'For small studios and teams earning under $3M/year in revenue';
      default: return '';
    }
  }

  const renderTitleBlock = (): React.ReactElement => {
    return (
      <div>
        <h3 cy-test-id="title" className="text-white inter text-28 leading-40 font-bold">
          {getTitle()}
        </h3>
        <h5 cy-test-id="subtitle" className="mt-2 text-a-gray inter text-16 leading-24 font-normal">
          {getSubtitle()}
        </h5>
      </div>
    );
  }

  const selectVariant = (variant: PlanVariantResponse) => {
    if (variant.id === currentPlan.plan_variant.id) return;
    onSelectedPlanVariant(variant);
  }

  const renderVariantSelectionButton = (variant: PlanVariantResponse): React.ReactElement => {
    return (
      <button
        key={variant.id}
        cy-test-id={`variant-selection-button-${variant.id}`}
        className={`
          py-1.5 px-5 rounded-[33px] h-10 inter text-16 leading-24 font-medium border-[1px]
          ${variant.id === currentPlan.plan_variant.id && disabledPlanVariantButtonClass}
          ${variant.id === selectedPlanVariant?.id && activePlanVariantButtonClass}
          ${variant.id !== currentPlan.plan_variant.id && variant.id !== selectedPlanVariant?.id && inactivePlanVariantButtonClass}
        `}
        disabled={variant.id === currentPlan.id}
        onClick={() => selectVariant(variant)}
      >
        {variant.recurring_credits}
      </button>
    );
  }

  const renderVariantSelectionBlock = (): React.ReactElement => {
    return (
      <div>
        <h5 cy-test-id="credits-selection-label" className="text-white inter text-16 leading-24 font-semibold">
          Credits per {selectedInterval}
        </h5>
        <div className="w-full mt-2 flex flex-row gap-2">
          {filteredPlanVariants.map(variant => renderVariantSelectionButton(variant))}
        </div>
        <div className="w-full mt-6 h-[51px]">
          {selectedPlanVariant && (
            <div cy-test-id="price-holder">
              <span className="text-white inter font-bold text-20 leading-44">
                $
              </span>
              <span className="text-white inter font-bold text-40 leading-44">
                {parseFloat(selectedPlanVariant.price).toFixed(0)}
              </span>
              <span className="text-a-light-gray font-normal text-16 leading-32">
                {' '}/ {selectedInterval === 'month' ? 'mo' : 'yr'}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div cy-test-id="plan-variant-selection" className="w-full flex flex-col gap-6 p-6 bg-a-dark-gray rounded-[5px]">
      {renderTitleBlock()}
      {renderVariantSelectionBlock()}
    </div>
  )
};

const mapStateToProps = (state: ApplicationState): PlanVariantSelectionStateProps => ({
  currentPlan: state.auth.subscription as SubscriptionDetail,
});

export default connect(mapStateToProps)(PlanVariantSelection);
