import { BuyMoreCreditsDialogProps, BuyMoreCreditsDialogStateProps } from '../../types/components/credits/BuyMoreCreditsDialog';
import { SelectedCreditOptionConfig } from '../../types/components/credits/BuyCreditsOptions';
import { CreditPlanConfig, PlanVariantResponse } from '../../types/api/SubscriptionTypes';
import { CreditPackResponse } from '../../types/api/CreditsTypes';
import { ApplicationState } from '../../types/state/storeTypes';
import { User } from '../../types/api/UsersTypes';
import { StripeCustomerData } from '../../types/api/stripe/customer';

import { useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { hideModal } from '../../state/actions/buyMoreCreditsActions';
import { incrementAvailableCredits } from '../../state/actions/creditActions';
import { addNotification } from '../../state/actions/notificationsActions';
import getLicenseTypes from '../../hooks/getLicenseTypes';
import creditHelpers from '../../helpers/creditsHelpers';

import BuyCreditsOptions from './BuyCreditsOptions';
import { Dialog } from '@headlessui/react';
import { NewExitIcon } from '../../modules/Icons';
import Link from 'next/link';
import CardSelectionAndPaymentBlock from '../checkout/CardSelectionAndPaymentBlock';
import ColoredSidebarNotification from '../shared/ColoredSidebarNotification';
import CreditPackSelection from './CreditPackSelection';
import SelectedPlanVariantDetails from '../checkout/SelectedPlanVariantDetails';
import StripeCreditBalanceBlock from '../payment/StripeCreditBalanceBlock';

const BuyMoreCreditsDialog: React.FC<BuyMoreCreditsDialogProps> = (props: BuyMoreCreditsDialogProps) => {
  const { licenseId, isOpen, creditsMissing, dialogMode, user } = props;
  const { licenseTypes } = getLicenseTypes();
  const { getCreditPriceTextFor } = creditHelpers;
  const dispatch = useDispatch();

  const [selectedOption, setSelectedOption] = useState<SelectedCreditOptionConfig>();
  const [valid, setValid] = useState<boolean>();
  const [discount, setDiscount] = useState<number>(0);

  const onClose = () => {
    onOptionSelected(undefined)
    dispatch(hideModal());
  }

  const onOptionSelected = (selectedOption: SelectedCreditOptionConfig) => {
    setSelectedOption(selectedOption);
    setDiscount(0);
  }

  const onStripeCreditBalanceLoaded = (data: StripeCustomerData) => {
    if (data.balance >= 0) return;
    const balance = data.balance / 100 * -1;
    setDiscount(balance);
  };

  const onCreditPackSelected = (pack: CreditPackResponse, isCustomAmount: boolean, customAmount?: number) => {
    const selectedOptionConfig: SelectedCreditOptionConfig = {
      type: 'creditPack',
      data: pack,
      isCustomCreditPack: isCustomAmount,
      customCreditPackAmount: customAmount,
    };
    onOptionSelected(selectedOptionConfig);
  };

  const onCreditAmountSelected = (amount: number) => {
    if (!selectedOption || selectedOption.type !== 'creditPack') return;
    const updatedOptionConfig = { ...selectedOption, customCreditPackAmount: amount };
    onOptionSelected(updatedOptionConfig);
  }

  const renderTitle = (): React.ReactElement => {
    let title: string;
    switch (dialogMode) {
      case 'not-enough-credits': title = 'You need more credits'; break;
      case 'buy-more-credits': title = 'Buy more credits'; break;
      default: break;
    };
    return (
      <h1
        className="text-white block text-center eurostile text-24 md:text-[32px] font-bold leading-[36px] uppercase"
        cy-test-id="dialog-title">
        {title}
      </h1>
    );
  }

  const renderSubtitle = (): React.ReactElement => {
    switch (dialogMode) {
      case 'not-enough-credits':
        return (
          <h3
            className="text-a-light-gray mt-3 block text-center inter text-16 leading-24 font-normal"
            cy-test-id="dialog-subtitle">
            You need <span className="text-white font-bold">{creditsMissing}</span> more credits
            to purchase this product
          </h3>
        );
      default:
        return <></>;
    }
  }

  const displaySelectOptionCTA = (): boolean =>
    !selectedOption && dialogMode !== 'buy-more-credits';

  const renderNotificationBody = (): React.ReactElement => {
    return (
      <>
        <span className="block inter text-16 text-white leading-24 font-normal">
          {
            selectedOption.type === 'creditPack' ? 'Credit purchase successful!' : 'Subscription upgrade successful!'
          }
        </span>
        <span className="block mt-1 inter text-14 text-a-light-gray leading-22 font-normal">
          You can now access your new credits.
        </span>
      </>
    )
  }

  const renderCreditsOptions = (): React.ReactElement => {
    switch (dialogMode) {
      case 'not-enough-credits':
        return (
          <BuyCreditsOptions
            licenseId={licenseId}
            licenseTypes={licenseTypes}
            creditsMissing={creditsMissing}
            onOptionSelected={onOptionSelected}
            onValidationStatusChanged={setValid}
          />
        );
      case 'buy-more-credits':
        return (
          <div cy-test-id="credit-pack-selection-wrapper">
          <CreditPackSelection
            licenseId={licenseId}
            onValidationStatusChanged={setValid}
            onCreditAmountSelected={onCreditAmountSelected}
            onCreditPackSelected={onCreditPackSelected}
            creditsMissing={1}
            autoSelectFirstOption={true}
          />
          </div>
        );
      default: return <></>;
    }
  }

  const onPurchaseCompleted = () => {
    const { data, isCustomCreditPack, customCreditPackAmount, type } = selectedOption;
    if (type === 'creditPack') {
      const incrementCreditsValue = isCustomCreditPack ? customCreditPackAmount : (data as CreditPackResponse).recurring_credits;
      dispatch(incrementAvailableCredits(incrementCreditsValue));
    }
    dispatch(
      addNotification({
        body: <ColoredSidebarNotification color="green" body={renderNotificationBody()} />,
        customUi: true
      })
    );
    onClose();
  }

  const getOrderDescription = (): string => {
    if (!selectedOption) return '';
    const { type, data, isCustomCreditPack, customCreditPackAmount } = selectedOption;
    switch (type) {
      case 'creditPack':
        if (isCustomCreditPack) return getCreditPriceTextFor(data as CreditPackResponse, customCreditPackAmount);
        return getCreditPriceTextFor(data as CreditPackResponse);
      case 'creditPlan':
        const plan = data as unknown as CreditPlanConfig;
        return `${plan.recurring_credits} Credits per ${plan.interval}`;
    }
  };

  const getOrderPrice = (): number => {
    if (!selectedOption) return 0;
    const { type, data, isCustomCreditPack, customCreditPackAmount } = selectedOption;
    switch (type) {
      case 'creditPack':
        const pack = data as CreditPackResponse;
        if (isCustomCreditPack) return customCreditPackAmount * parseFloat(pack.cost_per_credit);
        return parseFloat(pack.price);
      case 'creditPlan':
        const plan = data as unknown as CreditPlanConfig;
        // TODO test
        const ret = parseFloat(plan.price) - discount;
        return ret < 0 ? 0 : ret;
    }
  };

  const submitDisabled = (): boolean => {
    return !selectedOption || (selectedOption?.type === 'creditPack' && !valid);
  };

  if (user?.team?.role_name == 'Artist' || user?.team?.role_name == 'Download Only') {
    return (
      <Dialog open={isOpen} onClose={onClose} className="relative z-[51]">
        <div
          className="fixed inset-0 flex items-center justify-center bg-black/60 overflow-x-hidden"
          cy-test-id="buy-more-credits-dialog">
          <Dialog.Panel className="max-h-[100vh]">
            <div
              cy-test-id="dialog-main"
              className="md:w-[400px] relative rounded-[10px] bg-gray-800 p-2 md:p-[40px]">
              <NewExitIcon
                className="absolute top-5 right-2 md:top-12 md:right-10 cursor-pointer !stroke-[#F5F5F5] opacity-[50%] w-3 h-3 md:w-[18px] md:h-[18px] scale-125"
                onClick={onClose}
              />
              <div className="mt-2 text-white">
                <h4 className="uppercase text-[24px] eurostile font-bold mb-4">Not authorized</h4>
                Your account is not authorized to buy credits on this team. For more credits, please reach out to your account administrator.
              </div>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    );
  }

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-[51]">
      <div
        className="fixed inset-0 flex items-center justify-center bg-black/60 overflow-x-hidden"
        cy-test-id="buy-more-credits-dialog">
        <Dialog.Panel className="max-h-[100vh]">
          <div
            cy-test-id="dialog-main"
            className="md:w-[768px] lg:w-[1024px] relative rounded-[10px] bg-gray-800 p-2 md:p-[40px]">
            <NewExitIcon
              className="absolute top-5 right-2 md:top-12 md:right-10 cursor-pointer !stroke-[#F5F5F5] opacity-[50%] w-3 h-3 md:w-[18px] md:h-[18px] scale-125"
              onClick={onClose}
            />
            {renderTitle()}
            {renderSubtitle()}
            <div className="flex flex-col md:flex-row gap-[40px] mt-8">
              <div className="w-full md:w-[50%] align-top">
                {licenseTypes && renderCreditsOptions()}
              </div>
              <div className="w-full md:w-[50%] align-top">
                <h3
                  className="text-white inter text-[21px] font-bold leading-[28px] mb-6"
                  cy-test-id="order-summary-title">
                  Order Summary
                </h3>
                <div className="w-full flex flex-col gap-6">
                  {
                    selectedOption?.type === 'creditPlan' &&
                    <div className="bg-[#353C3E] p-4 rounded-[5px]">
                      <SelectedPlanVariantDetails
                        selectedPlanVariant={selectedOption?.data as PlanVariantResponse}
                      />
                    </div>
                  }
                  {
                    selectedOption?.type !== 'creditPlan' &&
                    <div className="p-4 justify-between items-start self-stretch rounded-[5px] bg-[#353C3E] mt-[22px] h-[60px]">
                      <span
                        className="inter text-[18px] font-semibold text-white leading-[28px] capitalize"
                        cy-test-id="order-description">
                        {getOrderDescription()}
                      </span>
                      <span
                        className="inter text-[18px] font-semibold text-white leading-[28px] float-right"
                        cy-test-id="order-price">
                        ${getOrderPrice().toFixed(2)}
                      </span>
                    </div>
                  }
                  <div className="my-[22px] h-[40px] block">
                    <span className="text-white inter text-[18px] leading-24 font-medium">
                      Total:
                    </span>
                    <span
                      className="inter text-[28px] font-bold text-white leading-[40px] float-right"
                      cy-test-id="total-price">
                      ${getOrderPrice().toFixed(2)}
                    </span>
                  </div>
                  {/* TODO test integration */}
                  {
                    selectedOption?.type === 'creditPlan' &&
                    <StripeCreditBalanceBlock
                      className="p-4 justify-between items-start self-stretch rounded-[5px] bg-[#353C3E] text-white"
                      onDataLoaded={onStripeCreditBalanceLoaded}
                    />
                  }
                  <CardSelectionAndPaymentBlock
                    disableSubmit={submitDisabled()}
                    purchasedCreditOption={selectedOption}
                    onPurchaseCompleted={onPurchaseCompleted}
                    doNotRedirectAfterUpgrade={true}
                  />
                  {
                    displaySelectOptionCTA() &&
                    <h3
                      className="text-white text-center uppercase eurostile text-[21px] font-bold leading-[28px]"
                      cy-test-id="select-option-cta"
                    >
                      Please select an option to continue
                    </h3>
                  }
                </div>
                <div className="flex flex-col gap-8 mt-[22px]" cy-test-id="license-agreement-block">
                  <p className="text-[14px] text-center text-a-lighter-gray">
                    By clicking "Confirm & Pay" you agree to our
                    <Link className="text-a-blue" href="/terms-of-service">
                      {' '}
                      Terms of Service
                    </Link>{' '}
                    and
                    <Link className="text-a-blue" href="/licenses">
                      {' '}
                      License Agreement
                    </Link>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state: ApplicationState): BuyMoreCreditsDialogStateProps => ({
  user: state.auth.user as User,
  isOpen: state.buyMoreCredits.showModal,
  creditsMissing: state.buyMoreCredits.creditsMissing,
  dialogMode: state.buyMoreCredits.modalMode,
});

export default connect(mapStateToProps)(BuyMoreCreditsDialog);
