import { SubscriptionUpgradeProps, SubscriptionUpgradeStateProps } from '../../types/components/subscription-upgrade-modal/SubscriptionUpgrade';
import { SubscriptionDetail } from '../../types/api/SubscriptionTypes';
import { LicenseId, LicenseTypeDetail } from '../../types/api/LicenseTypesTypes';
import { PlanVariantResponse, SubscriptionInterval } from '../../types/api/SubscriptionTypes';
import { StripePaymentMethodsList } from '../../types/api/stripe/paymentMethodsList';
import { PaymentMethod } from '../../types/ui/paymentMethod';
import { ApplicationState } from '../../types/state/storeTypes';

import { Elements } from '@stripe/react-stripe-js';
import { NewExitIcon } from '../../modules/Icons';
import IntervalToggle from './IntervalToggle';
import PlanVariantSelection from './PlanVariantSelection';
import UpdateLicenseBlock from '../license-confirmation-modals/UpdateLicenseBlock';
import OrderSummary from '../checkout/OrderSummary';
import ColoredSidebarNotification from '../shared/ColoredSidebarNotification';
import CardSelection from './CardSelection';
import SelectedPlanVariantDetails from '../checkout/SelectedPlanVariantDetails';

import { stripeOptions } from '../../constants/stripe';
import { connect, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useRouter } from 'next/router';
import subscriptionUpgradeHelpers from '../../helpers/subscriptionUpgradeHelpers';
import subscriptionHelpers from '../../helpers/subscriptionHelpers';
import getPlanVariants from '../../hooks/getPlanVariants';
import getLicenseTypes from '../../hooks/getLicenseTypes';

import { addNotification } from '../../state/actions/notificationsActions';
import { hideUpgradeSubscriptionModal } from '../../state/actions/subscriptionUpgradeActions';
import { User } from '../../types/api/UsersTypes';

export const downgradeButtonStyle = 'bg-[#1f3d48b3] hover:bg-[#1f3d4880] text-a-blue';


export const renderUpgradeNotificationBody = (): React.ReactElement => {
  return (
    <>
      <span className="block inter text-16 text-white leading-24 font-normal">
        Upgrade successful!
      </span>
      <span className="block mt-1 inter text-14 text-a-light-gray leading-22 font-normal">
        Enjoy the benefits of your new plan instantly.
      </span>
    </>
  )
}

export const renderDowngradeNotificationBody = (): React.ReactElement => {
  return (
    <>
      <span className="block inter text-16 text-white leading-24 font-normal">
        Downgrade successful
      </span>
      <span className="block mt-1 inter text-14 text-a-light-gray leading-22 font-normal">
        Your plan will downgrade at the end of your billing cycle.
      </span>
    </>
  )
}

// TODO refactor tests
const SubscriptionUpgrade: React.FC<SubscriptionUpgradeProps> = ({
  currentPlan,
  user,
  onClose,
  selectedPlanVariantId,
  selectedLicenseId,
}) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const {
    isUpgradeOrDowngrade,
    getDowngradeTooltipText,
    getIntervalToPreselect,
    getPlanVariantsAvailableForSelection,
    getLicenseToPreselect,
  } = subscriptionUpgradeHelpers;
  const { paidForUsingPayPal } = subscriptionHelpers;

  const [selectedInterval, setSelectedInterval] = useState<SubscriptionInterval>();
  const [selectedLicenseType, setSelectedLicenseType] = useState<LicenseTypeDetail>();
  const [selectedPlanVariant, setSelectedPlanVariant] = useState<PlanVariantResponse>();
  const [filteredPlanVariants, setFilteredPlanVariants] = useState<Array<PlanVariantResponse>>([]);
  const [savedCards, setSavedCards] = useState<StripePaymentMethodsList>();
  const [currentIsHighestAvailableVariant, setCurrentIsHighestAvailableVariant] = useState<boolean>(false);

  const getSelectedLicenseId = (): LicenseId => {
    return selectedLicenseId || currentPlan.license.id as LicenseId;
  }

  const { data: planVariants } = getPlanVariants(getSelectedLicenseId());
  const { licenseTypes } = getLicenseTypes();

  const isSubscriptionDowngrade = (): boolean => {
    return isUpgradeOrDowngrade(selectedPlanVariant, { id: getSelectedLicenseId() }, currentPlan) === 'downgrade';
  }

  const getPaymentMethod = (): PaymentMethod => {
    if (paidForUsingPayPal(currentPlan)) return 'paypal';
    if (!savedCards) return;
    return savedCards?.data.length ? 'saved_card' : 'card';
  }

  const getFirstSavedCardId = (): string => {
    if (!savedCards || !savedCards?.data.length) return;
    return savedCards.data[0].id;
  }

  const getSubmitButtonText = (): string => {
    if (!selectedPlanVariant) return '';
    const upgradeOrDowngrade = isUpgradeOrDowngrade(selectedPlanVariant, { id: getSelectedLicenseId() }, currentPlan);
    switch (upgradeOrDowngrade) {
      case 'upgrade': return 'Upgrade & Pay';
      case 'downgrade': return 'Downgrade';
      case false: return '';
    };
  }

  const getSubmitButtonStyle = (): string => {
    if (!selectedPlanVariant) return '';
    const upgradeOrDowngrade = isUpgradeOrDowngrade(selectedPlanVariant, { id: getSelectedLicenseId() }, currentPlan);
    if (upgradeOrDowngrade === 'downgrade') return downgradeButtonStyle;
    return '';
  }

  const getRenewalTooltipText = (): string => {
    if (!selectedPlanVariant) return '';
    const upgradeOrDowngrade = isUpgradeOrDowngrade(selectedPlanVariant, { id: getSelectedLicenseId() }, currentPlan);
    if (upgradeOrDowngrade !== 'downgrade') return '';
    const text = getDowngradeTooltipText(selectedPlanVariant, currentPlan, selectedInterval);
    return text;
  }

  const onUpgradeCompleted = () => {
    const upgradeOrDowngrade = isUpgradeOrDowngrade(selectedPlanVariant, { id: getSelectedLicenseId() }, currentPlan);
    let notificationBody: React.ReactElement;
    let notificationColor: 'blue' | 'green';
    switch (upgradeOrDowngrade) {
      case 'upgrade':
        notificationBody = renderUpgradeNotificationBody();
        notificationColor = 'green';
        break;
      case 'downgrade':
        notificationBody = renderDowngradeNotificationBody();
        notificationColor = 'blue';
        break;
      default:
        break;
    }
    dispatch(
      addNotification({
        body: <ColoredSidebarNotification color={notificationColor} body={notificationBody} />,
        customUi: true
      })
    );
    if (upgradeOrDowngrade === 'downgrade') router.push('/');
    onClose();
  }

  const executeDowngradeLogicAfterSubscription = (): boolean => {
    const upgradeOrDowngrade = isUpgradeOrDowngrade(selectedPlanVariant, { id: getSelectedLicenseId() }, currentPlan) === 'downgrade';
    return upgradeOrDowngrade;
  }

  const displayCurrentPlanVariantBlock = (): boolean => {
    return !selectedPlanVariant && currentIsHighestAvailableVariant;
  }

  const goToPricingPage = () => {
    router.push('/pricing');
    dispatch(hideUpgradeSubscriptionModal());
  }

  useEffect(() => {
    initializeSelectionState();
  }, []);

  useEffect(() => {
    initializeSelectionState();
  }, [planVariants, licenseTypes]);

  const initializeSelectionState = () => {
    if (!planVariants?.length || !licenseTypes?.length) return;
    let planVariantAutoSelect: PlanVariantResponse;
    if (selectedPlanVariantId) {
      planVariantAutoSelect = planVariants.find(v => v.id === selectedPlanVariantId && v.id !== currentPlan.plan_variant.id);
    }
    const _selectedLicenseType = getLicenseToPreselect(licenseTypes, currentPlan, planVariantAutoSelect);
    setSelectedLicenseType(_selectedLicenseType);
    const _selectedInterval = planVariantAutoSelect?.interval || getIntervalToPreselect(currentPlan, planVariants);
    setSelectedInterval(_selectedInterval);
    filterPlanVariants(_selectedLicenseType, _selectedInterval, planVariantAutoSelect);
  }

  const onIntervalChanged = (interval: SubscriptionInterval) => {
    setSelectedInterval(interval);
    filterPlanVariants(selectedLicenseType, interval);
  }

  const filterPlanVariants = (
    selectedlicenseType: LicenseTypeDetail,
    interval: SubscriptionInterval,
    planVariantAutoSelect?: PlanVariantResponse,
  ) => {
    const filteredPlanVariants = subscriptionUpgradeHelpers.filterPlanVariants(planVariants, interval);
    setFilteredPlanVariants(filteredPlanVariants);
    const availableVariants = getPlanVariantsAvailableForSelection(currentPlan, filteredPlanVariants, selectedlicenseType?.id);
    if (!availableVariants?.length) {
      setCurrentIsHighestAvailableVariant(true);
      return;
    }
    const selectedPlanVariant = availableVariants.find(v => v.id === planVariantAutoSelect?.id) || availableVariants[0];
    if (!selectedPlanVariant) return;
    setSelectedPlanVariant(selectedPlanVariant);
  }

  const renderCurrentPlanBlock = (): React.ReactElement => {
    return (
      <div className="w-full flex flex-col gap-8">
        <SelectedPlanVariantDetails selectedPlanVariant={currentPlan.plan_variant}/>
        <p className="text-white inter">
          You are currently on the highest available plan variant for your license.
        </p>
      </div>
    );
  }

  const renderContents = (): React.ReactElement => {
    if (!filteredPlanVariants || !licenseTypes || !selectedLicenseType) return;

    if (user?.team?.role_name == 'Artist' || user?.team?.role_name == 'Download Only') {
      return (
        <div className="text-gray-500 text-center max-w-[500px] mx-auto mt-4">
          Your account is not authorized to buy make subscription changes for this team. To upgrade your plan, please reach out to your account administrator.
        </div>
      );
    }
    return (
      <>
        <div className="mt-10 flex flex-col md:flex-row gap-5 md:gap-16 items-center justify-center">
          <IntervalToggle
            selectedInterval={selectedInterval}
            onSelectedInterval={onIntervalChanged}
          />
        </div>
        <div className="flex flex-col md:flex-row gap-6 mt-8">
          <div className="flex flex-col gap-6 w-full md:w-1/2">
            {
              !!filteredPlanVariants &&
              <PlanVariantSelection
                filteredPlanVariants={filteredPlanVariants}
                selectedInterval={selectedInterval}
                selectedPlanVariant={selectedPlanVariant}
                onSelectedPlanVariant={setSelectedPlanVariant}
              />
            }
            {
              !paidForUsingPayPal(currentPlan) &&
              <CardSelection onCardsLoaded={setSavedCards} />
            }
            <UpdateLicenseBlock onClose={onClose} />
          </div>
          <div className="w-full md:w-1/2 p-6 bg-a-dark-gray rounded-[5px]">
            {
              selectedPlanVariant &&
              <OrderSummary
                selectedLicenseId={getSelectedLicenseId()}
                selectedPlanVariant={selectedPlanVariant}
                selectedPaymentMethod={getPaymentMethod()}
                onFlowCompleted={onUpgradeCompleted}
                customSubmitButtonText={getSubmitButtonText()}
                customSubmitButtonClass={getSubmitButtonStyle()}
                customRenewalTooltip={getRenewalTooltipText()}
                doNotExecuteAfterSubscriptionLogic={executeDowngradeLogicAfterSubscription()}
                selectedCardId={getFirstSavedCardId()}
                isSubscriptionDowngrade={isSubscriptionDowngrade()}
                licenseConfirmNotRequired={true}
                renderCreditWarning={currentPlan?.active && currentPlan?.legacy_current_credit_balance > 0}
                remainingLegacyCredits={currentPlan?.legacy_current_credit_balance ? currentPlan?.legacy_current_credit_balance : null}
              />
            }
            {
              displayCurrentPlanVariantBlock() &&
              renderCurrentPlanBlock()
            }
          </div>
        </div>
      </>
    );
  }

  return (
    <div
      cy-test-id="subscription-upgrade"
      className="max-md:w-[100vw] md:w-[768px] lg:w-[1024px] relative rounded-[10px] bg-gray-800 p-2 md:p-8">
      <h3 className="block text-white text-center eurostile uppercase text-21 md:text-32 leading-36 font-bold">
        Change your plan
      </h3>
      <NewExitIcon
        className="absolute right-[22px] top-[22px] md:right-8 md:top-8 cursor-pointer !stroke-[#F5F5F5] opacity-[50%] w-3 h-3 md:w-[18px] md:h-[18px] scale-125"
        onClick={onClose}
      />
      {renderContents()}
    </div>
  );
};

const mapStateToProps = (state: ApplicationState): SubscriptionUpgradeStateProps => ({
  user: state.auth.user as User,
  currentPlan: state.auth.subscription as SubscriptionDetail,
  selectedPlanVariantId: state.subscriptionUpgrade.selectedPlanVariantId,
  selectedLicenseId: state.subscriptionUpgrade.licenseId,
});

const container = (props: SubscriptionUpgradeProps) => {
  const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);
  return (
    <Elements
      stripe={stripePromise}
      options={{
        mode: 'setup',
        currency: 'usd',
        appearance: stripeOptions,
        paymentMethodTypes: ['card']
      }}
    >
      <SubscriptionUpgrade {...props} />
    </Elements>
  );
};

export default connect(mapStateToProps)(container);
