import { useState, useEffect } from 'react';

import RemainingSpotsPercentageBar from './RemainingSpotsPercentageBar';

const OfferPanel: React.FC<any> = (props) => {
  const { tier } = props;

  const [panelTitle, setPanelTitle] = useState<string>('');
  const [statusBasedClasses, setStatusBasedClasses] = useState<string>('');

  const discountPercent = Math.round(100 * tier.discount);
  const remainingSpotsPercent = Math.round((tier.slots_remaining / tier.slots) * 100);

  useEffect(() => {
    initUI();
  }, [tier]);

  function initUI(): void {
    switch (tier.status) {
      case 'soldOut':
        setPanelTitle('SOLD OUT');
        setStatusBasedClasses('bg-red-100 text-black-friday-banner-red-brighter');
        break;
      case 'current':
        setPanelTitle('Current Offer:');
        setStatusBasedClasses('md:outline md:outline-[3px] md:outline-white bg-red-800 text-white');
        break;
      case 'next':
        setPanelTitle('Next Offer:');
        setStatusBasedClasses('bg-red-100 text-black-friday-banner-red-brighter');
        break;
      default:
        break;
    }
  }

  const shouldBlink = (): boolean => {
    return tier.status === 'current' && tier?.slots_remaining > 0 && tier?.slots_remaining <= 99;
  }

  const animatedStyle = {
    animation: 'blinker 1s step-start infinite',
  }

  return (
    <div
      className={`max-md:w-full py-2.5 px-10 lg:px-4 xl:px-10 2xl:px-10 md:rounded-[10px] eurostile cursor-default ${statusBasedClasses}`}
    >
      <span className="hidden md:block text-center text-[20px] leading-[24px] font-bold inter">
        {panelTitle}
      </span>
      <span className="block text-center mb-[6px] md:text-[32px] md:leading-[36px] lg:text-[28px] lg:leading-[30px] font-bold 2xl:text-[36px] 2xl:leading-[44px] md:mt-[6px] 1900p:text-[48px] 1900p:leading-[56px] uppercase">
        {discountPercent}% off
      </span>

      {tier.slots > 0 && (
        <span
          className={`block text-center font-normal text-[18px] lg:text-[12px] 2xl:text-[18px] leading-[24px] inter mb-2`}
          style={shouldBlink() ? animatedStyle : {}}
        >
          <span className="font-bold">{Math.ceil(tier.slots_remaining)}</span> left of{' '}
          <span className="font-bold">{Math.ceil(tier.slots)}</span> spots
        </span>
      )}

      {tier.slots == -1 && (
        <span className="block text-center font-normal text-[18px] lg:text-[12px] 2xl:text-[18px] leading-[24px] inter mb-2">
          <span className="font-bold">Until the sale ends on Nov 30</span>
        </span>
      )}

      {tier.slots > 0 && tier.status === 'current' && (
        <RemainingSpotsPercentageBar remainingPercentage={remainingSpotsPercent} />
      )}
    </div>
  );
};

export default OfferPanel;
