const AVFXPlusBanner: React.FC<any> = () => {
  return (
    <div className="md:px-5 lg:px-0 left-0 w-full z-50 bg-black-friday-banner-red-default py-[38px] text-center cursor-default">
      <h1 className="eurostile text-[22px] md:text-[42px] md:leading-[52px] lg:text-[48px] lg:leading-[56px] uppercase text-white font-bold">
        avfx+ makes Black Friday better
      </h1>
      <h3 className="inter text-[14px] md:text-[18px] lg:text-[21px] md:leading-[26px] lg:leading-[28px] font-medium text-white mt-[13px]">
        Subscribers receive up to 40% Off <span className="underline">in addition</span> to the
        Black Friday discount!
      </h3>
    </div>
  );
};

export default AVFXPlusBanner;
